<template>
    <li class="ct-card-highlight__card" :style="{ 'color': colour }">
        <img :src="icon_link" class="ct-card-highlight__icon" />
        <div class="ct-card-highlight__container">
            <span class="ct-card-highlight__title" v-if="highlightTranslation.title" v-text="highlightTranslation.title" />
            <span class="ct-card-highlight__description" v-if="highlightTranslation.description"
                v-text="highlightTranslation.description" />
            <span class="ct-card-highlight__value" :class="{ 'ct-card-highlight__value--sup': hasSupText }">
                <span v-if="highlightTranslation.before_value" v-html="highlightTranslation.before_value" />
                <span class="ct-card-highlight__value--text" :class="{ 'ct-card-highlight__value--sup': hasSupText }">
                    <span v-text="tansformFigure" />
                    <span v-html="highlightTranslation.after_value" v-if="highlightTranslation.after_value" />
                </span>
            </span>
        </div>
    </li>
</template>

<script setup>
import { computed } from 'vue'
import themeColors from "@root/tailwind/colours.config";
import { highlightsColourOrders } from '@root/constants'

const props = defineProps({
    highlight: {
        type: Object,
        default: () => ({})
    },
    translations: {
        type: Object,
        default: () => ({})
    },
    index: {
        type: Number,
        default: 0
    }

})
const { key, value, icon_link } = props.highlight
const highlightTranslation = props.translations[key]
const tansformFigure = computed(() => Intl.NumberFormat('en-US').format(value))
const colour = computed(() => {
    const { set, default: defaultColour } = themeColors['highlights']
    const colourKey = highlightsColourOrders[props.index]
    return set[colourKey] ? set[colourKey] : defaultColour

})
const hasSupText = computed(() => {
    const before_value = String(highlightTranslation.before_value).toLowerCase()
    const after_value = String(highlightTranslation.after_value).toLowerCase()
    return (before_value.includes('<sup>') && before_value.includes('</sup>')) || (after_value.includes('<sup>') && after_value.includes('</sup>'))
})
</script>

<style scoped lang="postcss">
.ct-card-highlight {
    &__card {
        @apply shadow-default grow flex flex-col items-start gap-3 shrink-0 border px-3 py-6 border-solid md:justify-center md:gap-6 md:p-6;
    }

    &__container {
        @apply flex flex-col gap-3 md:gap-6 lg:w-60;
    }

    &__icon {
        @apply w-[2.10125rem] h-8 shrink-0 md:w-12 md:h-[2.85544rem];
    }

    &__title {
        @apply body-text-1 text-black;
    }

    &__description {
        @apply small-text-2 text-black;
    }

    &__value {
        @apply subtitle-1;

        &--text {
            @apply flex gap-1;
        }

        &--sup {
            @apply items-end;
        }
    }
}
</style>