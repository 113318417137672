<template>
    <div class="ct-chart-data-summary-doughnut" v-if="hasData">
        <div class="ct-chart-data-summary-doughnut__container">
            <div class="ct-chart-data-summary-doughnut__chart-container">
                <canvas ref="chartCanvas" />
            </div>
        </div>
        <LegendDataSummary v-bind="{
            legends: topXList,
            translations
        }" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import LegendDataSummary from '@components/Legend/DataSummary'
import Chart from 'chart.js';

const props = defineProps({
    topXList: {
        type: Array,
        default: () => []
    },
    labels: {
        type: Array,
        default: () => []
    },
    dataset: {
        type: Object,
        default: () => ({})
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})

const chartCanvas = ref()
const chartObject = ref()
const hasData = computed(() => props.dataset.data.length > 0)

function getConfig(data, dataset) {
    return {
        type: 'doughnut',
        data,
        options: {
            responsive: true,
            aspectRatio: 2,
            maintainAspectRatio: false,
            animation: { animateScale: true },
            legend: { display: false },
        }
    }
}
function initChart() {
    const { labels, dataset } = props
    const data = {
        labels,
        datasets: [dataset]
    }
    chartObject.value = new Chart(chartCanvas.value, getConfig(data, dataset))
}

onMounted(() => {
    if (hasData.value)
        initChart()
})
onUnmounted(() => {
    if (chartObject.value) { chartObject.value.destroy() }
})
</script>

<style lang="postcss" scoped>
.ct-chart-data-summary-doughnut {
    @apply bg-theme-charts-background flex flex-col justify-center gap-3 p-3 md:flex-row md:gap-6 md:p-6;

    &__container {
        @apply flex justify-center;
    }

    &__chart-container {
        @apply relative shrink-0 w-full md:w-[18.75rem] h-[18.75rem];
    }
}
</style>