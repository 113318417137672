<template>
    <li class="ct-card-case-study__card">
        <img v-if="caseStudy.image" :src="caseStudy.image"
            class="ct-card-case-study__image ct-card-case-study__image--regular-size" />
        <div v-else class="ct-card-case-study__image--not-found ct-card-case-study__image--regular-size">
            <IconImageNoFound class="ct-card-case-study__icon" />
        </div>
        <div class="ct-card-case-study__container">
            <a class="ct-card-case-study__country-name" target="_blank" :href="caseStudy.country_page_hyperlink"
                v-text="caseStudy.country_name" />
            <span class="ct-card-case-study__icca-name" v-text="caseStudy.icca_name" />
            <ButtonGoToLink v-bind="{
                link: caseStudy.hyperlink,
                text: buttonTranslation.read_more,
                classes: { 'button-go-to-link__button': 'ct-card-case-study__button' }
            }" />
        </div>
    </li>
</template>

<script setup>
import { ref } from 'vue'
import ButtonGoToLink from "@components/Button/GoToLink"
import IconImageNoFound from "@components/Icon/ImageNoFound"

const props = defineProps({
    translations: {
        type: Object,
        required: true
    },
    caseStudy: {
        type: Object,
        required: true
    }
})
const buttonTranslation = ref(props.translations.buttons)
</script>

<style scoped lang="postcss">
.ct-card-case-study {
    &__card {
        @apply basis-1/2 flex flex-col grow items-center shadow-default;
    }

    &__container {
        @apply flex flex-col w-full items-start gap-3 p-6 min-h-[11.0625rem] md:justify-between md:gap-0 md:min-h-[14.3125rem];
    }

    &__image {
        @apply object-cover object-top;

        &--regular-size {
            @apply w-full h-[12.5rem] md:h-[13.5rem];
        }

        &--not-found {
            @apply flex items-center justify-center bg-theme-case-study-no-image;
        }
    }

    &__icon {
        @apply w-[4.01938rem] h-[3.375rem];
    }

    &__country-name {
        @apply text-theme-primary subtitle-1;
    }

    &__icca-name {
        @apply subtitle-2 lg:subtitle-3;
    }

    :deep(&__button) {
        @apply button-case-study;
    }


}
</style>