<template>
    <div v-show="showTooltip" ref="tooltipModal" role="tooltip" class="ct-tooltip-base"
        :class="[screenIsMobile ? 'ct-tooltip-base--full' : 'ct-tooltip-base--normal']">
        <div class="ct-tooltip-base__container">
            <div class="ct-tooltip-base__header">
                <span class="ct-tooltip-base__title" v-text="title" />
                <button @click="close">
                    <IconPlus class="ct-tooltip-base__close-button" />
                </button>
            </div>
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import IconPlus from "@components/Icon/Plus"
import { useStore } from "@app/javascript/store/store";

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    showTooltip: {
        type: Boolean,
        default: false
    }
})

const tooltipModal = ref()
const emit = defineEmits('close')
const store = useStore()
const screenIsMobile = computed(() => store.getters['screenSize/isMobile'])

function close() {
    emit('close')
}

</script>

<style lang="postcss" scoped>
.ct-tooltip-base {
    @apply z-10;

    &--normal {
        @apply absolute;
    }

    &--full {
        @apply overflow-visible left-0 top-0 bottom-0 right-0 h-full w-full fixed bg-gray-200/75 flex items-center justify-center;

    }

    &__container {
        @apply flex flex-col bg-white w-[19rem] md:w-[27.5rem];
    }

    &__header {
        @apply flex justify-between items-center px-3 py-6 md:hidden;
    }

    &__close-button {
        @apply text-black rotate-45 h-4 w-[0.99994rem];
    }
}
</style>