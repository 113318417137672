export function disableBackgroundScrolling() {
  document.querySelector("body").style.overflow = "hidden";
}
export function enableBackgroundScrolling() {
  document.querySelector("body").style.overflow = "";
}
export function scrollingIsDisabled() {
  return document.querySelector("body").style.overflow === "hidden";
}
/*
    Make sure only to use this in pair disableScrolling + enableScrolling in one component
    not disableScrolling in one component and enableScrolling somewhere else
*/
