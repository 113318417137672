import { render, staticRenderFns } from "./CaseStudy.vue?vue&type=template&id=ddde3bb0&scoped=true"
import script from "./CaseStudy.vue?vue&type=script&setup=true&lang=js"
export * from "./CaseStudy.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CaseStudy.vue?vue&type=style&index=0&id=ddde3bb0&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddde3bb0",
  null
  
)

export default component.exports