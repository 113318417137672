import { render, staticRenderFns } from "./IccaInfo.vue?vue&type=template&id=03feb536&scoped=true"
import script from "./IccaInfo.vue?vue&type=script&setup=true&lang=js"
export * from "./IccaInfo.vue?vue&type=script&setup=true&lang=js"
import style0 from "./IccaInfo.vue?vue&type=style&index=0&id=03feb536&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03feb536",
  null
  
)

export default component.exports