<template>
  <div v-if="loaded" class="ct-mapbox-source">
    <slot />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, inject, provide } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  map: {
    type: Object,
    default: undefined
  },

  source: {
    type: [Object, Promise],
    required: true
  },

  requestTransformer: {
    type: Object,
    default: undefined
  },

  up: {
    type: Function,
    default: undefined
  },

  down: {
    type: Function,
    default: undefined
  }
})
const loaded = ref(false)
/* Get Map Object From Map Component */
const mapObject = inject('getMapObject')
/* Provide Map Object to Next Component in slot */
provide('getMapObject', mapObject)

function removeSourceIfLoaded(sourceId) {
  try {
    if (mapObject.value.getSource(sourceId)) {
      mapObject.value.removeSource(sourceId)
    }
  } catch (error) {
    console.warn(error)
  }
}

onMounted(() => {
  removeSourceIfLoaded(props.id)
  mapObject.value.addSource(props.id, props.source)
  if (props.up) {
    props.up({ map: mapObject.value })
  }

  loaded.value = true
})
onUnmounted(() => {
  if (props.down) {
    props.down({ map: mapObject.value })
  }
  removeSourceIfLoaded(props.id)
})
</script>

<style lang="postcss" scoped>
.ct-mapbox-source {
  @apply hidden;
}
</style>
