import { render, staticRenderFns } from "./ErrorBanner.vue?vue&type=template&id=6ff66bad&scoped=true"
import script from "./ErrorBanner.vue?vue&type=script&setup=true&lang=js"
export * from "./ErrorBanner.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff66bad",
  null
  
)

export default component.exports