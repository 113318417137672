import themeColors from "@root/tailwind/colours.config";

// Mapox configuration
export const ELEMENT_SCREENSHOT_MAPBOX_COMPATIBLE_CONFIGURATION = {
  /**
   * If true , the map's canvas can be exported to a PNG using
   * map.getCanvas().toDataURL().
   * This is false by default as a performance optimization.
   * @see https://docs.mapbox.com/mapbox-gl-js/api/map/?size=n_10_n#map-parameters
   */
  preserveDrawingBuffer: false,
};
// For Map zoom related config
export const MIN_ZOOM = 1.3;
export const MAX_ZOOM = 16;
export const MAP_ZOOM_DEFAULT = 1.3;
export const POLYGON_TOLERANCE = 0; // The higher, the more the polygons are simplified.
// Source IDs
export const SOURCE_ID_POINTS = "SOURCES.POINTS";
export const SOURCE_ID_POLYGONS = "SOURCES.POLYGONS";
// Layer IDs
export const LAYER_ID_POLYGONS = "LAYERS.POLYGONS";
export const LAYER_ID_POINTS = "LAYERS.POINTS";
// Other IDs
export const UNEP_ADMIN_BOUNDARY_LAYER_ID = "airport-label";
// colours config for polygons/points
export const POLYGON_COLOUR = themeColors.primary;
export const POLYGON_OUTLINE_COLOR = themeColors.primary;
export const POINT_CIRCLE_COLOUR = themeColors.primary;
export const POLYGON_OPACITY = 0.8;
export const POINT_CIRCLE_RADIUS = 6;
export const CIRCLE_OPACITY = 0.7;
export const POINT_STROKE_WIDTH = 1;
// Map related variables end

// Data Summary related variables start
// Highlights related variables
/* for chaning icon colours you have to go direct to app/assets/images/icons change the currentColour attribute */
export const highlightsColourOrders = ["green", "orange", "purple", "blue"];
// Chart related variables
export const pieChartsColourOrders = ["orange", "green", "purple", "blue"];
export const barChartsColourOrders = ["green", "purple", "orange", "blue"];
/*  All avaliable colour choices are in tailwind/colours.config.js
    if a colour choice in this array is not in the tailwind/colours.config.js then it will use first set of colours in the file and ignore your colour choice specified here
    i,e you put rainbow as one of the colour order here but you don not have the rainbow colours set up in tailwind/colours.config.js then it will use black as the colour
*/
// Data Summary related variables end

// All LOCAL STORAGE NAMES
export const LOCALSTORAGENAMES = {
  mapBanner: "mapBanner",
};

export const GOOGLEANALYTICSID = {
  production: "G-BX12GGXYTN",
  staging: "G-E3F3E4EP6M",
};

export const COOKIENAMES = {
  consent: "cookieconsent_status",
  emailDownloadGeoFile: "email_download_geo_file",
};
