import { render, staticRenderFns } from "./Disclaimer.vue?vue&type=template&id=0dad0046&scoped=true"
import script from "./Disclaimer.vue?vue&type=script&setup=true&lang=js"
export * from "./Disclaimer.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Disclaimer.vue?vue&type=style&index=0&id=0dad0046&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dad0046",
  null
  
)

export default component.exports