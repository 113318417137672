<template>
  <flickity 
    :options="flickityOptions"
    ref="flickity"
  >
    <img
      v-for="(imageUrl,index) in imageurls" 
      class="gallery__photo"
      :data-flickity-lazyload="imageUrl"
      :key="`${index}image`"
      alt="Photos relevant to the case study"
    />
  </flickity>
</template>

<script>
import Flickity from "vue-flickity";
import imagesLoaded from 'vue-images-loaded';

export default {
  name: "CarouselFlickity",
  components: { Flickity },
  directives: { imagesLoaded },
  props:['imageurls'],
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        // imagesLoaded: true,
        lazyLoad: 1
      },
      };
  },
  computed:{

  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    }
  }
};
</script>