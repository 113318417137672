import { render, staticRenderFns } from "./GeoDownload.vue?vue&type=template&id=49f2fc24&scoped=true"
import script from "./GeoDownload.vue?vue&type=script&setup=true&lang=js"
export * from "./GeoDownload.vue?vue&type=script&setup=true&lang=js"
import style0 from "./GeoDownload.vue?vue&type=style&index=0&id=49f2fc24&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f2fc24",
  null
  
)

export default component.exports