import { render, staticRenderFns } from "./Highlight.vue?vue&type=template&id=e4fe4114&scoped=true"
import script from "./Highlight.vue?vue&type=script&setup=true&lang=js"
export * from "./Highlight.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Highlight.vue?vue&type=style&index=0&id=e4fe4114&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4fe4114",
  null
  
)

export default component.exports