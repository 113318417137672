<template>
    <div :class="getCustomClass(classes, 'ct-button-download')">
        <button :class="getCustomClass(classes, 'ct-button-download__button')" @click="toggleOptions">
            <IconDownload class="ct-button-download__download-icon" />
            <span class="ct-button-download__text" v-text="title" />
            <IconArrowSimple class="ct-button-download__arrow" :class="{
                'ct-button-download__arrow--hide': showOptions,
            }" />
        </button>
        <ol v-if="showOptions" :class="getCustomClass(classes, 'ct-button-download__options')">
            <li class="ct-button-download__option" v-for="(option, index) in options" v-text="option.name"
                :key="`option.name${index}`" @click="clickOption(option)" />
        </ol>
    </div>
</template>
<script setup>
import { ref } from 'vue'
import IconDownload from "@components/Icon/Download"
import IconArrowSimple from "@components/Icon/ArrowSimple"
import getCustomClass from '@composition/getCustomClass';

const emit = defineEmits('click')
const props = defineProps({
    title: {
        type: String,
        default: "Button"
    },
    options: {
        type: Array,
        default: () => []
    },
    classes: {
        type: Object,
        default: () => ({}),
    }
})
const showOptions = ref(false)

function toggleOptions() {
    showOptions.value = !showOptions.value
}
function clickOption(value) {
    emit('click', value)
}
</script>

<style scoped lang="postcss">
.ct-button-download {
    @apply relative w-fit bg-transparent;

    &__button {
        @apply button-download;
    }

    &__download-icon {
        @apply button-download-icon;
    }

    &__text {
        @apply button-text;
    }

    &__arrow {
        @apply button-download-arrow;

        &--hide {
            @apply button-download-arrow-rotate;
        }
    }

    &__options {
        @apply button-download-options;
    }

    &__option {
        @apply button-download-option;
    }
}
</style>