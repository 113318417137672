export default {
  install(Vue, { store }) {
    function handleResize() {
      store.dispatch("screenSize/updateWindowWidth");
    }
    window.addEventListener("resize", handleResize);

    /**
     * #TODO: To find a way to add  window.removeEventListener("resize", handleResize); unmounted
     * Can't use mixin because it will be fired several times cos we register multiple vue instances
     * */
  },
};
