<template>
    <ol class="ct-mapbox-icca-info__items">
        <li class="ct-mapbox-icca-info__item">
            <span :class="`ct-mapbox-icca-info__title ${iccaInfo.site_id}`" v-text="popUpTranslations.local_name" />
            <span class="ct-mapbox-icca-info__value" v-text="iccaInfo.name_local_language" />
        </li>
        <li class="ct-mapbox-icca-info__item">
            <span class="ct-mapbox-icca-info__title" v-text="popUpTranslations.reported_area.title" />
            <span class="ct-mapbox-icca-info__value ct-mapbox-icca-info__value--sup">
                <span v-text="iccaInfo.reported_area" />
                <span v-html="popUpTranslations.reported_area.unit" />
            </span>
        </li>
        <li class="ct-mapbox-icca-info__item">
            <span class="ct-mapbox-icca-info__title" v-text="popUpTranslations.community" />
            <span class="ct-mapbox-icca-info__value" v-text="iccaInfo.community_name" />
        </li>
        <li class="ct-mapbox-icca-info__item--case-study" v-if="caseStudyLink">
            <span class="ct-mapbox-icca-info__title" v-text="popUpTranslations.case_study" />
            <a class="ct-mapbox-icca-info__view-link" target="_blank" :href="caseStudyLink">
                <span class="ct-mapbox-icca-info__title" v-text="popUpTranslations.view" />
                <IconArrow class="ct-mapbox-icca-info__arrow" />
            </a>
        </li>

    </ol>
</template>

<script setup>
import { ref, computed } from 'vue'
import IconArrow from "@components/Icon/Arrow"

const props = defineProps({
    iccaInfo: {
        type: Object,
        default: () => ({})
    },
    sitePath: {
        type: String,
        required: true,
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})

const popUpTranslations = ref(props.translations.mapbox.icca_info)
const caseStudyLink = computed(() => {
    if (props.iccaInfo.case_study_links) {
        const caseStudyLinks = JSON.parse(props.iccaInfo.case_study_links)
        return caseStudyLinks[props.sitePath]
    }
    else {
        return null
    }

})
</script>

<style scoped lang="postcss">
.ct-mapbox-icca-info {
    &__items {
        @apply flex flex-col items-start gap-3 lg:gap-6;
    }

    &__item {
        @apply flex flex-col items-start gap-1.5;

        &--case-study {
            @apply flex flex-col items-start gap-3;
        }
    }

    &__title {
        @apply text-white body-text-1;
    }

    &__value {
        @apply text-white body-text-2 flex gap-1;

        &--sup {
            @apply items-end;
        }
    }

    &__view-link {
        @apply flex items-center underlined-text gap-1.5 lg:gap-3;
    }

    &__arrow {
        @apply text-white h-[0.75rem] w-[0.55106rem] lg:h-[1.125rem] lg:w-[0.83rem] hover:cursor-pointer;
    }
}
</style>