import Cookies from "js-cookie";
import { COOKIENAMES } from "@root/constants";

const defaultExpiresAt = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);
export function setUserConsentToCookie(decision) {
  Cookies.set(COOKIENAMES.consent, decision === true, {
    expires: defaultExpiresAt,
  });
}
export function getUserConsentFromCookie() {
  return Cookies.get(COOKIENAMES.consent);
}
export function userConsentedForOptionalCookies() {
  return getUserConsentFromCookie() === "true";
}
export function setCookie(cookieName, value, expires) {
  Cookies.set(cookieName, value, {
    // If no date is provided then use defualt expires date
    expires: expires || defaultExpiresAt,
  });
}

export function getCookie(cookieName) {
  return Cookies.get(cookieName);
}
export function removeCookie(cookieName) {
  Cookies.remove(cookieName);
}
