<template>
  <div class="ct-banner-expandable">
    <div class="ct-banner-expandable__header">
      <div class="ct-banner-expandable__header--left">
        <IconExclamationCircle class="ct-banner-expandable__exclamation" />
        <div class="ct-banner-expandable__title">
          <slot name="title" />
        </div>
      </div>
      <button @click="toggleBanner">
        <IconHideMinus class="ct-banner-expandable__banner-toggler" v-if="showBanner" />
        <IconShowExpand class="ct-banner-expandable__banner-toggler" v-else />
      </button>
    </div>
    <div class="ct-banner-expandable__divide" />
    <div class="ct-banner-expandable__container" v-show="showBanner">
      <slot name="description" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import IconHideMinus from "@components/Icon/HideMinus"
import IconShowExpand from "@components/Icon/ShowExpand"
import IconExclamationCircle from "@components/Icon/ExclamationCircle"

defineProps({
  title: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  }
})

const showBanner = ref(true)

function toggleBanner() {
  showBanner.value = !showBanner.value
}
</script>

<style lang="postcss" scoped>
.ct-banner-expandable {
  &__exclamation {
    @apply w-6 h-6 text-theme-primary;
  }

  &__header {
    @apply bg-theme-banner flex justify-between items-center p-3 md:p-6;

    &--left {
      @apply flex items-center gap-3;
    }
  }

  &__banner-toggler {
    @apply w-6 h-6 text-theme-primary;
  }


  &__title {
    @apply subtitle-2;
  }

  &__divide {
    @apply w-full h-[0.0625rem] bg-theme-primary;
  }

  &__container {
    @apply bg-theme-banner flex items-center gap-2.5 p-3 md:p-6;
  }

  &__description {
    @apply small-text-2;
  }
}
</style>
