<template>
  <div class="ct-mapbox-banner">
    <div class="ct-mapbox-banner__header">
      <span class="ct-mapbox-banner__title" v-text="bannerTranslation.title" />
      <!-- v-if="showButton" -->
      <button @click="toggleBanner()">
        <IconHideMinus class="ct-mapbox-banner__banner-toggler" v-if="showBanner" />
        <IconShowExpand class="ct-mapbox-banner__banner-toggler" v-else />
      </button>
    </div>
    <div class="ct-mapbox-banner__divide" />
    <div class="ct-mapbox-banner__container" v-show="showBanner">
      <span class="ct-mapbox-banner__description" v-text="bannerTranslation.description" />
      <ModalGeoDownload v-bind="{
        translations: translations,
        privacyPolicyLink,
        dataCollectionTermsLink
      }" />
    </div>


  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import IconHideMinus from "@components/Icon/HideMinus"
import IconShowExpand from "@components/Icon/ShowExpand"
import ModalGeoDownload from "@components/Modal/GeoDownload"
import { LOCALSTORAGENAMES } from '@root/constants'
import { useStore } from "@app/javascript/store/store";

const props = defineProps({
  translations: {
    type: Object,
    required: true
  },
  dataCollectionTermsLink: {
    type: String,
    required: true
  },
  privacyPolicyLink: {
    type: String,
    required: true
  }
})
const store = useStore()
// find user previous visit choice of show/hide banner in localstorage
const userPreviousVisitChoice = localStorage.getItem(LOCALSTORAGENAMES.mapBanner)
const screenIsDesktop = computed(() => store.getters['screenSize/isDesktop'])
const bannerTranslation = ref(props.translations.mapbox.banner)
/**
 * !==false in case there is no previous choice so it shows by default
 * or if screenIsDesktop is false then show by default
 * */
const showBanner = ref(userPreviousVisitChoice !== "false" || !screenIsDesktop.value)

function toggleBanner(openBanner) {
  // Use openBanner value if it is set if not then toggle the banner
  showBanner.value = openBanner !== undefined ? openBanner : !showBanner.value
  localStorage.setItem(LOCALSTORAGENAMES.mapBanner, showBanner.value);
}
// Make sure the bannr is not hidden when resizing the screen from desktop to tablet or mobile
watch(() => screenIsDesktop.value, (screenIsDesktop) => {
  if (!screenIsDesktop)
    toggleBanner(true)
})
</script>

<style lang="postcss" scoped>
.ct-mapbox-banner {
  @apply bg-theme-primary w-full lg:absolute lg:z-10 lg:top-3 lg:left-3 lg:w-[39rem] lg:max-w-none;

  &__header {
    @apply flex flex-col items-start w-full px-3 py-6 lg:flex-row lg:justify-between lg:p-6;
  }

  &__banner-toggler {
    @apply hidden lg:block text-theme-primary lg:w-6 lg:h-6;
  }


  &__title {
    @apply text-white subtitle-1;
  }

  &__divide {
    @apply w-full h-[0.0625rem] bg-white;
  }

  &__container {
    @apply flex flex-col items-start w-full gap-6 p-6;
  }

  &__description {
    @apply text-white body-text-2;
  }

  &__button {

    :deep(&-options) {
      @apply button-download-options bg-theme-primary min-w-[19rem] md:min-w-[19rem];
    }
  }
}
</style>
