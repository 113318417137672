<template>
    <!--
        home-data-manual-bg is in app/assets/stylesheets/icca_registry/home.scss
        see tailwind.config.js for why this is done this way
    -->
    <div class="ct-entry-data-manual home-data-manual-bg">
        <div class="ct-entry-data-manual__container--main ">
            <div class="ct-entry-data-manual__header">
                <span class="ct-entry-data-manual__title" v-text="dataManualTranslation.title" />
            </div>
            <div class="ct-entry-data-manual__container--content">
                <p class="ct-entry-data-manual__description" v-text="dataManualTranslation.description" />
                <ButtonDownload class="ct-entry-data-manual__button" v-bind="{
                    title: buttonTranslation.download,
                    options,
                    classes: downloadButtonClasses
                }" @click="downloadManual" />
            </div>
            <div class="ct-entry-data-manual__citation">
                <span class="ct-entry-data-manual__citation--text">
                    <span v-text="imageCitationTranslation.citation" class="ct-entry-data-manual__citation--title" />
                    <span v-text="dataManualTranslation.citation" />
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ButtonDownload from "@components/Button/Download"

const props = defineProps({
    backgroundImage: {
        type: String,
        default: null
    },
    translations: {
        type: Object,
        required: true
    },
    options: {
        type: Array,
        required: true
    },
    imageCitationTranslation: {
        type: Object,
        required: true
    },
})

const dataManualTranslation = ref(props.translations.entry.data_manual)
const buttonTranslation = ref(props.translations.buttons)
function downloadManual(option) {
    window.open(option.value, '_blank');
}
const downloadButtonClasses = ref({
    "ct-button-download__options": "ct-entry-data-manual__button-options"
})
</script>

<style scoped lang="postcss">
.ct-entry-data-manual {

    @apply lg:w-[67.25rem] p-6 lg:p-10;

    &__header {
        @apply flex flex-col items-start gap-3 border-b border-white border-solid w-full;
    }

    &__title {
        @apply subtitle-1 text-white;
    }

    &__container {
        &--main {
            @apply flex flex-col items-start gap-6;
        }

        &--content {
            @apply flex flex-col items-start gap-6 lg:flex-row lg:justify-between w-full;
        }

    }

    &__description {
        @apply body-text-2 text-white md:w-[38.25rem] lg:w-[42.625rem];
    }

    &__button {
        :deep(&-options) {
            @apply button-download-options w-full;
        }
    }

    &__citation {
        @apply tailwind-citation;

        &--text {
            @apply p-1 tailwind-citation__text max-w-[25rem];
        }

        &--title {
            @apply tailwind-citation__title;
        }
    }
}
</style>