<template>
    <ol class="ct-entry-shortcut-links">
        <CardShortcutLink v-bind="{ shortcutLink, imageCitationTranslation, isLast: shortcutLinks.length - 1 === index }"
            :key="`${index}CardShortcutLink`" v-for="(shortcutLink, index) in shortcutLinks" />
    </ol>
</template>

<script setup>
import { computed } from 'vue'
import CardShortcutLink from "@components/Card/ShortcutLink"
import { useStore } from "@app/javascript/store/store";

const props = defineProps({
    shortcutLinks: {
        type: Array,
        required: true
    },
    imageCitationTranslation: {
        type: Object,
        required: true
    },
})
const store = useStore()


const screenIsDesktop = computed(() => store.getters['screenSize/isDesktop'])
</script>

<style scoped lang="postcss">
.ct-entry-shortcut-links {
    @apply flex flex-col gap-4 md:gap-6 lg:flex-row;
}
</style>