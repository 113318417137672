<template>
    <li class="card-data-summary-chart">
        <div class="card-data-summary-chart__header">
            <span class="card-data-summary-chart__title" v-text="transformedTitle"
                :class="{ 'card-data-summary-chart__title--no-view-more': transformedChartInformation.fullList.length === 0 }" />
            <div class="card-data-summary-chart__view-more" v-if="transformedChartInformation.fullList.length > 0">
                <button class="card-data-summary-chart__view-more-button" v-text="buttonTranslations.view_full_list"
                    @click.self="toggleModal()" />
                <TooltipDataSummaryChart v-if="showTooltip" class="card-data-summary-chart__modal" v-bind="{
                    title: transformedTitle,
                    showTooltip
                }" @close="toggleModal(false)">
                    <ol class="card-data-summary-chart__items">
                        <li class="card-data-summary-chart__item" :key="`card-data-summary-chart__item${index}`"
                            v-for="(legend, index) in transformedChartInformation.fullList">
                            <span class="card-data-summary-chart__legend--text">
                                <span v-text="legend.key" />
                                <span v-text="'-'" />
                                <span v-text="legend.numberOfTimesTheNameAppear"
                                    class="card-data-summary-chart__legend--bold" />
                            </span>
                        </li>
                    </ol>
                </TooltipDataSummaryChart>
            </div>
        </div>
        <div class="card-data-summary-chart__container">
            <span class="card-data-summary-chart__description" v-text="description" />
            <ChartDataSummaryBar v-if="isBarChart" :translations="translations"
                v-bind="transformedChartInformation.chart" />
            <ChartDataSummaryDoughnut v-else :translations="translations" v-bind="transformedChartInformation.chart" />
        </div>

    </li>
</template>

<script setup>
import { ref, computed, watch, onUnmounted } from 'vue'
import ChartDataSummaryDoughnut from '@components/Chart/DataSummary/Doughnut'
import ChartDataSummaryBar from '@components/Chart/DataSummary/Bar'
import TooltipDataSummaryChart from '@components/Tooltip/DataSummaryChart'
import { pieChartsColourOrders, barChartsColourOrders } from '@root/constants'
import { charts as chartColours } from "@root/tailwind/colours.config";
import { disableBackgroundScrolling, enableBackgroundScrolling, scrollingIsDisabled } from '@composition/backgroundScrollingControl'
import { useStore } from "@app/javascript/store/store";
import { replaceTranslationArguments } from '@composition/replaceTranslationArguments'

const props = defineProps({
    isBarChart: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    rawChartInformation: {
        type: Array,
        default: () => []
    },
    showTopXInLegends: {
        type: Number,
        required: true
    },
    currentChartIndex: {
        type: Number,
        default: 0
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})
const store = useStore()
const buttonTranslations = props.translations.buttons
const dataSummaryChartTranslations = props.translations.card.data_summary_chart
const showTooltip = ref(false)
const totalLength = computed(() => props.rawChartInformation.length)
const screenIsMobile = computed(() => store.getters['screenSize/isMobile'])
const transformedTitle = `${props.title} (${replaceTranslationArguments(dataSummaryChartTranslations.number_with_types, { number: totalLength.value })})`
function toggleModal(show) {
    showTooltip.value = show == undefined ? !showTooltip.value : show
}
function getColourSet() {
    const colourList = []
    const { sets: chartColourSets, default: defaultColour } = chartColours
    const avaliableColourSetsKeys = Object.keys(chartColourSets)
    const currentChartIndex = props.currentChartIndex
    // get colourSetIndex i,e there are 4 colours avaliable then it will be 0,1,2,3, 0,1,2,3 so it rotates the colour choice
    const colourSetIndex = currentChartIndex % avaliableColourSetsKeys.length
    const colourSetKey = props.isBarChart ? barChartsColourOrders[colourSetIndex] : pieChartsColourOrders[colourSetIndex]

    const colourSet = chartColourSets[colourSetKey]
    for (let index = 0; index < props.showTopXInLegends; index++) {
        let colour = defaultColour
        if (colourSet) {
            // so colours can be rendered from darkest to lightest
            const colourSetKeysSorted = Object.keys(colourSet).sort((a, b) => b - a)
            // Get corresponding colour key
            const colourKey = colourSetKeysSorted[index]
            const colourCode = colourSet[colourKey]
            // if there is no nough colours in the set then use the default colour
            colour = colourCode || defaultColour
        }
        colourList.push(colour)
    }

    return colourList
}

const transformedChartInformation = computed(() => {
    const chartLabels = []
    const chartData = []
    const topXList = []
    const fullList = []
    const colourSet = getColourSet()
    for (var index = 0; index < props.rawChartInformation.length; index++) {
        const { value: numberOfTimesTheNameAppear, key } = props.rawChartInformation[index]
        if (index < props.showTopXInLegends) {
            chartData.push(numberOfTimesTheNameAppear)
            chartLabels.push(key)
            topXList.push({
                colour: colourSet[index],
                key,
                numberOfTimesTheNameAppear,
            })
        }
        fullList.push({
            key,
            numberOfTimesTheNameAppear,
        })
    }

    return {
        fullList,
        showTopXInLegends: props.showTopXInLegends,
        chart: {
            isBarChart: props.isBarChart,
            topXList,
            labels: chartLabels,
            dataset: {
                data: chartData,
                backgroundColor: colourSet,
                borderColor: colourSet,
                borderWidth: 1
            }
        }
    }
})

watch(() => showTooltip.value, (newValue) => {
    if (screenIsMobile.value)
        newValue ? disableBackgroundScrolling() : enableBackgroundScrolling()
})
onUnmounted(() => {
    if (scrollingIsDisabled())
        enableBackgroundScrolling()
})
</script>

<style scoped lang="postcss">
.card-data-summary-chart {
    @apply flex flex-col shadow-default;

    &__header {
        @apply flex justify-between flex-wrap gap-y-3 p-3 md:p-6 border-b border-solid border-theme-grey-light;
    }

    &__title {
        @apply subtitle-1 max-w-[18.125rem] md:max-w-[28rem] lg:max-w-[25.4rem];

        &--no-view-more {
            @apply max-w-none;
        }
    }

    &__container {
        @apply flex flex-col gap-3 p-3 md:gap-6 md:p-6;
    }

    &__description {
        @apply small-text-2 lg:h-[7.5rem];
    }

    &__view-more {
        @apply relative shrink-0;

        &-button {
            @apply underlined-text-2 text-theme-primary uppercase;
        }

    }

    &__modal {
        @apply md:right-0 md:border-theme-primary md:border md:border-solid md:mb-10;
    }

    &__items {
        @apply md:shadow-default h-[34.25rem] overflow-y-auto;
    }

    &__item {
        @apply p-3;
    }

    &__legend {
        &--text {
            @apply data-summary-legend-text;
        }

        &--bold {
            @apply data-summary-legend-text--bold;
        }
    }

}
</style>