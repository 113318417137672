<template>
      <BannerExpandable>
            <template #title>
                  <span class="ct-entry-data-summary-disclaimer__title" v-text="disclaimerTranslations.title" />
            </template>
            <template #description>
                  <span class="ct-entry-data-summary-disclaimer__description" v-html="description" />
            </template>
      </BannerExpandable>
</template>

<script setup>
import BannerExpandable from "@components/Banner/Expandable"
import { replaceTranslationArguments, buildLinkElement } from '@composition/replaceTranslationArguments'

const props = defineProps({
      translations: {
            type: Object,
            default: () => ({})
      },
      siteLocale: {
            type: String,
            required: true,
      },
      attributeInformationDocumentLinks: {
            type: Object,
            default: () => ({})
      }
})
const disclaimerTranslations = props.translations.entry.data_summary.disclaimer
const description = replaceTranslationArguments(disclaimerTranslations.description, { link: buildLinkElement(props.attributeInformationDocumentLinks[props.siteLocale], disclaimerTranslations.attribute_information_document) })
</script>

<style lang="postcss" scoped>
.ct-entry-data-summary-disclaimer {
      &__title {
            @apply subtitle-2;
      }


      &__description {
            @apply small-text-2;
      }
}
</style>