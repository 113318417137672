<template>
  <div v-if="loaded" class="ct-mapbox-layer">
    <slot />
  </div>
</template>

<script setup>
import { inject, provide, onMounted, onUnmounted, ref } from 'vue'
import {
  UNEP_ADMIN_BOUNDARY_LAYER_ID
} from '@root/constants'

const props = defineProps({
  id: {
    type: String,
    required: true
  },

  layer: {
    type: [Object, Promise],
    required: true
  },

  order: {
    type: Number,
    default: undefined
  },

  up: {
    type: Function,
    default: undefined
  },

  down: {
    type: Function,
    default: undefined
  }
})

/* Get Map Object From Map Component */
const mapObject = inject('getMapObject')
/* Provide Map Object to Next Component in slot */
provide('getMapObject', mapObject)
const loaded = ref(false)

onMounted(async () => {
  if (mapObject.value.getLayer(props.id)) {
    mapObject.value.removeLayer(props.id)
  }
  mapObject.value.addLayer({ id: props.id, ...props.layer }, UNEP_ADMIN_BOUNDARY_LAYER_ID)


  if (props.up) {
    props.up({ map: mapObject.value })
  }

  loaded.value = true
})
onUnmounted(() => {
  if (props.down) {
    props.down({ map: mapObject.value })
  }

  if (mapObject.value.getLayer(props.id)) {
    mapObject.value.removeLayer(props.id)
  }
})
</script>

<style lang="postcss" scoped>
.ct-mapbox-layer {
  @apply hidden;
}
</style>
