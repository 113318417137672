<template>
  <div class="ct-banner-main">
    <div class="ct-banner-main__container">
      <IconExclamationCircle class="ct-banner-main__container-icon" />
      <div class="ct-banner-main__body">
        <span v-if="slots.left" class="ct-banner-main__left">
          <slot name="left" />
        </span>
        <div v-if="slots.right" class="ct-banner-main__right">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import IconExclamationCircle from "@components/Icon/ExclamationCircle"
import { useSlots } from 'vue'

const slots = useSlots()
</script>
<style lang="postcss" scoped>
.ct-banner-main {
  @apply bg-theme-warning p-[0.5rem] lg:p-[1.5rem] rounded-md;

  &__container {
    @apply flex gap-[0.75rem] lg:gap-[1.5rem];

    &-icon {
      @apply shrink-0 w-6 h-6 text-theme-primary lg:justify-center lg:items-center;
    }
  }

  &__body {
    @apply flex justify-start flex-col gap-[0.75rem];
  }

  &__left {
    @apply grow-0 min-w-[7rem] body-text-2 lg:subtitle-1;
  }

  &__right {
    @apply body-text-2;
  }
}
</style>
