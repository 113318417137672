<template>
  <div v-if="show" class="cookie-banner">
    <div class="cookie-banner__container">
      <p class="cookie-banner__description" v-html="description" />
      <ul class="cookie-banner__decisions">
        <li class="cookie-banner__decision">
          <button class="cookie-banner__button cookie-banner__button--accept" @click.prevent="onAcceptButtonClick">
            {{ cookieTranslations.accept }}
          </button>
        </li>
        <li class="cookie-banner__decision">
          <button class="cookie-banner__button" @click.prevent="onRejectButtonClick">
            {{ cookieTranslations.reject }}
          </button>
        </li>
      </ul>
    </div>
    <div class="cookie-banner__overlay" />
  </div>
</template>

<script>
import { setUserConsentToCookie, getUserConsentFromCookie } from '@composition/cookie'
import { replaceTranslationArguments, buildLinkElement } from '@composition/replaceTranslationArguments'

export default {
  inject: ['serverEnviromentIs'],
  props: {
    termsConditionLink: {
      type: String,
      default: "/"
    },
    translations: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      show: false,
      bodyElement: document.querySelector('body'),
    }
  },
  setup(props) {
    const cookieTranslations = props.translations.banner.cookie
    const description = replaceTranslationArguments(cookieTranslations.description, {
      link: buildLinkElement(props.termsConditionLink, cookieTranslations.terms_conditions)
    })
    return {
      cookieTranslations,
      description
    }
  },
  methods: {
    onAcceptButtonClick() {
      setUserConsentToCookie(true)
      this.optIn()
      location.reload();
    },
    onRejectButtonClick() {
      setUserConsentToCookie(false)
      this.optOut()
      location.reload();
    },
    showHideBanner(show) {
      this.show = show
      this.bodyElement.style.overflow = show ? "hidden" : ''
    },
    userIsInTermsConditionPage() {
      // We don't enable banner if user is in terms and conditions page. Otherwise, they cannot see the terms. also check if T&C page does exist
      return window.location.pathname === this.termsConditionLink && this.termsConditionLink !== "/"
    },
    optIn() {
      /*
        when landing to a page after accepted we need to optin to activate google analytics as default is disabled
        see /Users/yuelongh/Documents/WCMC/icca-registry/app/javascript/packs/vue.js to find the enable config
      */
      this.$gtag.optIn();
      // Enable Hotjar
      if (this.serverEnviromentIs.production)
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
          h._hjSettings = { hjid: 3653010, hjsv: 6 };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script'); r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    },
    optOut() {
      // when landing to a page after accepted we need to optin to activate google analytics as default is disabled
      this.$gtag.optOut()
    }
  },
  beforeMount() {
    if (this.$gtag) {
      const userDecision = getUserConsentFromCookie()
      if (userDecision === undefined && !this.userIsInTermsConditionPage()) {
        this.showHideBanner(true)
      }
      else if (userDecision === "true") {
        this.optIn();
      }
    }
  },
  beforeDestroy() {
    if (this.bodyElement.style.overflow === "hidden")
      this.bodyElement.style.overflow = ''
  }
}
</script>

<style lang="postcss" scoped>
.cookie-banner {
  @apply lg:flex lg:items-center;

  &__container {
    @apply bg-theme-primary z-[100] w-full fixed bottom-0 p-3 md:p-6 flex flex-col gap-1 lg:gap-4 lg:items-center lg:flex-row;
  }

  &__description {
    @apply body-text-2 text-white;

    a {
      @apply underline;
    }
  }

  &__decisions {
    @apply body-text-2 text-white mt-4 flex items-center;
  }

  &__decision {
    @apply subtitle-2 text-white;
  }

  &__button {
    @apply text-lg px-5 py-3 border flex gap-3 items-center uppercase;

    &--accept {
      @apply p-3 border-solid border-white;
    }
  }

  &__overlay {
    @apply bg-white/50 fixed inset-0 z-[50];
  }
}
</style>
