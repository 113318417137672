<template>
    <ol class="ct-entry-data-summary-highlights">
        <CardDataSummaryHighlight v-bind="{
            highlight,
            translations: highlightsTranslation,
            index
        }" :key="`CardHighlight${index}`" v-for="(highlight, index) in highlights" />
    </ol>
</template>

<script setup>
import { ref, computed } from 'vue'
import CardDataSummaryHighlight from '@components/Card/DataSummary/Highlight'

const props = defineProps({
    highlights: {
        type: Array,
        default: () => []
        /* Example of the object in highlights
            {
                "key": "all_icca_records",
                "value": 390,
                "icon_link": "http://localhost:3001/icons/data_summary/all_icca_records.svg"
            },
        */
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})
const highlightsTranslation = ref(props.translations.entry.data_summary.highlights)
</script>
<style lang="postcss" scoped>
.ct-entry-data-summary-highlights {
    @apply grid grid-cols-2 content-start gap-4 md:gap-6 lg:grid-cols-none lg:flex lg:rounded-lg lg:items-stretch;
}
</style>
