<template>
    <BannerWarning>
        <template #left>{{ errorMessagesTranslation.error_occurred }}</template>
        <template #right>
            <ol>
                <li :key="`errorMsg${index}`" v-for="(errorMessage, index) in errorMessages" v-text="errorMessage" />
            </ol>
        </template>
    </BannerWarning>
</template>

<script setup>
import { ref } from 'vue'
import BannerWarning from "@components/Banner/Warning"

const props = defineProps({
    errors: {
        type: Array,
        default: () => []
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})
const errorMessagesTranslation = props.translations.error_messages
const dataSummaryBannerTranslation = ref(props.translations.entry.data_summary.error_banner)
const errorMessages = ref([])


errorMessages.value.push(...dataSummaryBannerTranslation.value.default_error_messages)
if (props.errors.length > 0) {
    errorMessages.value.push(...props.errors)
}
</script>
<style lang="postcss" scoped></style>
