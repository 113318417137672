<template>
  <div class="ct-tooltip-citation" :class="[{ 'ct-tooltip-citation--active': isActive }]">
    <button v-if="screenIsDesktop" tabindex="0" :aria-describedby="id" :aria-expanded="isActive"
      class="ct-tooltip-citation__trigger" @mouseenter="toggleTooltip(true)" @mouseleave="toggleTooltip(false)">
      <IconExclamationCircle class="ct-tooltip-citation__trigger" />
    </button>
    <button v-else tabindex="0" :aria-describedby="id" :aria-expanded="isActive" class="ct-tooltip-citation__trigger"
      @click="toggleTooltip">
      <IconExclamationCircle class="ct-tooltip-citation__trigger" />
    </button>
    <div v-show="isActive" :id="id" role="tooltip" class="ct-tooltip-citation__container" :class="{
      'ct-tooltip-citation__container--last': isLast
    }">
      <span class="ct-tooltip-citation__container--arrow">
        <span class="ct-tooltip-citation__arrow-icon" :class="{
          'ct-tooltip-citation__arrow-icon--last': isLast
        }" />
      </span>
      <div class="ct-tooltip-citation__text">
        <span v-text="title" class="ct-tooltip-citation__title" /><span v-text="description" />
      </div>
    </div>
  </div>
</template>

<script>
import mixinPopupCloseListeners from '../../mixins/mixin-popup-close-listeners'
import IconExclamationCircle from "@components/Icon/ExclamationCircle"
import { computed } from 'vue'
import { useStore } from "@app/javascript/store/store";

export default {
  name: 'Tooltip',
  mixins: [mixinPopupCloseListeners({ closeCallback: 'closeTooltip' })],
  components: {
    IconExclamationCircle
  },
  props: {
    title: {
      type: String,
      default: "Image citation"
    },
    description: {
      type: String,
      default: "Image citation"
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const screenIsDesktop = computed(() => store.getters['screenSize/isDesktop'])

    return { screenIsDesktop }
  },
  data() {
    return {
      id: `tooltip_${this._uid}`,
      isActive: false
    }
  },

  mounted() {
    if (this.onHover) {
      const tooltipTrigger = this.$el.querySelector('.ct-tooltip-citation__trigger')

      tooltipTrigger.addEventListener('blur', () => {
        this.toggleTooltip(false)
      })
      tooltipTrigger.addEventListener('focus', () => {
        this.toggleTooltip(true)
      })
    }
  },

  methods: {
    toggleTooltip(boolean) {
      this.isActive = typeof boolean == 'boolean' ? boolean : !this.isActive
    },

    closeTooltip() {
      this.toggleTooltip(false)
    }
  }
}
</script>
<style lang="postcss" scoped>
.ct-tooltip-citation {
  @apply relative;

  &__trigger {
    @apply w-6 h-6 text-white cursor-pointer;
  }

  &__container {
    @apply absolute top-full z-10 w-[18.25rem] -right-1/2 mt-4 lg:right-auto lg:left-[-8.8rem];

    &--last {
      @apply lg:left-[-16rem];
    }

    &--arrow {
      @apply relative block;
    }
  }

  &__arrow-icon {
    @apply absolute -z-10 top-0 -translate-x-1/2 -translate-y-1/2 right-[3%] lg:right-[7.72rem] transform rotate-45 w-4 h-4 bg-theme-primary border-l border-t border-theme-primary;

    &--last {
      @apply lg:right-[3%];
    }

  }

  &__text {
    @apply p-2.5 bg-theme-primary tailwind-citation__text;
  }

  &__title {
    @apply tailwind-citation__title;
  }
}
</style>

