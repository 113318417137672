import { render, staticRenderFns } from "./Source.vue?vue&type=template&id=c46bd15e&scoped=true"
import script from "./Source.vue?vue&type=script&setup=true&lang=js"
export * from "./Source.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Source.vue?vue&type=style&index=0&id=c46bd15e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c46bd15e",
  null
  
)

export default component.exports