import { render, staticRenderFns } from "./Doughnut.vue?vue&type=template&id=aeb45e62&scoped=true"
import script from "./Doughnut.vue?vue&type=script&setup=true&lang=js"
export * from "./Doughnut.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Doughnut.vue?vue&type=style&index=0&id=aeb45e62&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeb45e62",
  null
  
)

export default component.exports