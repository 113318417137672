<template>
  <div v-show="popup.isOpen()" ref="mapboxPopupRef">
    <div class="ct-mapbox-pop-up__header">
      <span class="ct-mapbox-pop-up__title" v-text="title" />
      <button @click="togglePopup(false)">
        <IconPlus class="ct-mapbox-pop-up__close-button" />
      </button>
    </div>
    <div class="ct-mapbox-pop-up__divide" />
    <div class="ct-mapbox-pop-up__content">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { Popup } from 'mapbox-gl'
import { onMounted, onUnmounted, watch, inject, provide, ref } from 'vue';
import IconPlus from "@components/Icon/Plus"

const props = defineProps({
  configuration: {
    type: Object,
    default: () => ({})
  },

  lngLat: {
    type: Array,
    default: () => null
  },
  title: {
    type: String,
    default: ""
  }
})
const DEFAULT_CONFIGURATION = {
  closeButton: false,
  className: 'ct-mapbox-pop-up__container'
}
const EVENT_NAME_OPEN = 'open'
const EVENT_NAME_CLOSE = 'close'
// No idea why it throws an error if using defineEmits([EVENT_NAME_OPEN, EVENT_NAME_CLOSE])
const emit = defineEmits([`open`, `close`])
const mapboxPopupRef = ref()
/* Get Map Object From Map Component */
const mapObject = inject('getMapObject')
/* Provide Map Object to Next Component in slot */
provide('getMapObject', mapObject)

const popup = ref(new Popup({
  ...DEFAULT_CONFIGURATION,
  ...props.configuration,
}))
function emitClose() {
  emit(EVENT_NAME_CLOSE)
}
function emitOpen() {
  emit(EVENT_NAME_OPEN)
}
function togglePopup(show) {
  const isOpen = popup.value.isOpen()
  if (!show && isOpen) {
    popup.value.remove()
  }
  else if (show && !isOpen) {
    mapObject.value.flyTo({
      center: props.lngLat,
      speed: 1,
      zoom: mapObject.value.getZoom()
    })
    popup.value
      .setLngLat(props.lngLat)
      .setDOMContent(mapboxPopupRef.value)
      .addTo(mapObject.value)
  }
}
onMounted(() => {
  popup.value.on(EVENT_NAME_OPEN, emitOpen)
  popup.value.on(EVENT_NAME_CLOSE, emitClose)
})
onUnmounted(() => {
  popup.value.remove()
  popup.value.off(EVENT_NAME_CLOSE, emitClose)
  popup.value.off(EVENT_NAME_OPEN, emitOpen)
})
watch(() => props.lngLat, (lngLat) => {
  // Open popup when lngLat is not null
  togglePopup(!!lngLat)
})
</script>

<style lang="postcss" scoped>
:global(.mapboxgl-popup-content) {
  /* Set all styles with important otherwise it will not overwrite */
  @apply !p-0 !bg-black !w-[20.375rem] lg:w-[28rem];
}

:global(.mapboxgl-popup-tip) {
  /* Set the tip to black with important otherwise it will not overwrite */
  @apply !border-y-black;
}

.ct-mapbox-pop-up {
  &__container {
    @apply !max-w-none;
  }

  &__header {
    @apply flex justify-between items-center p-3;
  }

  &__close-button {
    @apply text-white h-[1rem] w-[1rem] rotate-45 hover:cursor-pointer;
  }

  &__title {
    @apply text-white body-text-1;
  }

  &__divide {
    @apply w-full h-[0.0625rem] bg-white;
  }

  &__content {
    @apply overflow-y-auto px-3 py-6 max-h-[18.375rem] lg:max-h-[21.625rem];
  }
}
</style>