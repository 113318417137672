export function replaceTranslationArguments(value, argumentsMap) {
  let newString = value;
  if (typeof newString === "string") {
    for (const [argument, argumentReplacement] of Object.entries(
      argumentsMap
    )) {
      let regex = new RegExp(`\{${argument}\}`, "g");
      newString = newString.replace(regex, argumentReplacement);
    }
  }
  return newString;
}
export function buildLinkElement(link, text) {
  return `<a class="tailwind-link--default" target="_blank" href="${link}"><span>${text}</span></a>`;
}
