export const breakpoints = {
  sm: 480,
  md: 768,
  lg: 1280,
};

export const screens = (function () {
  const breakpointsObject = {};
  for (const [screenSize, value] of Object.entries(breakpoints)) {
    breakpointsObject[screenSize] = `${value}px`;
  }
  return breakpointsObject;
})();
