<template>
    <div class="ct-chart-data-summary-bar" v-if="hasData">
        <div class="ct-chart-data-summary-bar__container">
            <div class="ct-chart-data-summary-bar__chart-container">
                <canvas ref="chartCanvas" />
            </div>
        </div>
        <LegendDataSummary v-bind="{
            legends: topXList,
            translations
        }" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import LegendDataSummary from '@components/Legend/DataSummary'
import Chart from 'chart.js';

const props = defineProps({
    topXList: {
        type: Array,
        default: () => []
    },
    labels: {
        type: Array,
        default: () => []
    },
    dataset: {
        type: Object,
        default: () => ({})
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})

const chartCanvas = ref()
const chartObject = ref()
const hasData = computed(() => props.dataset.data.length > 0)
function getConfig(data, dataset) {
    return {
        type: 'bar',
        data,
        options: {
            responsive: true,
            maintainAspectRatio: false,

            animation: { animateScale: true },
            legend: { display: false },
            scales: {
                xAxes: [{
                    ticks: {
                        callback: function (label, index) {
                            return dataset.data[index];
                        }
                    }
                },]
            }
        }
    }
}
function initChart() {
    const { labels, dataset } = props
    const data = {
        labels,
        datasets: [dataset]
    }
    chartObject.value = new Chart(chartCanvas.value, getConfig(data, dataset))
}

onMounted(() => {
    if (hasData.value)
        initChart()
})
onUnmounted(() => {
    if (chartObject.value) { chartObject.value.destroy() }
})
</script>

<style lang="postcss" scoped>
.ct-chart-data-summary-bar {
    @apply flex flex-col gap-3 md:gap-6;

    &__container {
        @apply flex justify-center gap-2.5 px-0 py-6 md:py-12 bg-theme-charts-background;
    }

    &__chart-container {
        @apply relative h-[12.5rem] w-full;
    }
}
</style>