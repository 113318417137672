<template>
  <li class="ct-card-shortcut-link" :style="backgroundImageStyle">
    <div class="ct-card-shortcut-link__container">
      <div class="ct-card-shortcut-link__container--tooltip">
        <TooltipCitation v-bind="{
          title: imageCitationTranslation.citation,
          description: shortcutLink.citation,
          isLast
        }" class="carousel__tooltip" />
      </div>
      <div class="ct-card-shortcut-link__container--content">
        <img class="ct-card-shortcut-link__icon" :alt="`${shortcutLink.title}-svg`" :src="shortcutLink.icon_link" />
        <span class="ct-card-shortcut-link__title" v-text="shortcutLink.title" />
        <p class="ct-card-shortcut-link__description" v-text="shortcutLink.description" />

        <ButtonGoToLink v-bind="{
          link: shortcutLink.hyperlink,
          text: shortcutLink.button,
          classes: { 'button-go-to-link__button': 'ct-card-shortcut-link__button' }
        }" />
      </div>
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import ButtonGoToLink from "@components/Button/GoToLink"
import TooltipCitation from "@components/Tooltip/Citation"

const props = defineProps({
  shortcutLink: {
    type: Object,
    required: true
  },
  imageCitationTranslation: {
    type: Object,
    required: true
  },
  isLast: {
    type: Boolean,
    default: false
  }
})
const imageLink = props.shortcutLink.background_image_link
const backgroundImageStyle = computed(() => ({
  'background': `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${imageLink}), lightgray 50% / cover no-repeat`,
  'background-size': 'cover',
  'background-repeat': 'no-repeat',
  'background-position': 'center'

}))
</script>
<style scoped lang="postcss">
.ct-card-shortcut-link {
  @apply relative grow shrink min-h-[22.0625rem] md:min-h-[25rem] lg:min-h-[27.125rem];

  &__container {
    @apply absolute p-6 grid grid-cols-1 content-between h-full w-full;

    &--tooltip {
      @apply w-full flex justify-end;
    }

    &--content {
      @apply md:w-[42rem] lg:w-[18.375rem] bottom-0 left-0 flex flex-col items-start gap-3 md:gap-6 lg:inline-flex lg:h-[21.375rem] lg:justify-between lg:shrink-0;
    }
  }

  &__icon {
    @apply w-[2.99931rem] h-[3rem] md:w-[3.37419rem] md:h-[3.375rem];
  }

  &__title {
    @apply text-white subtitle-1;
  }

  &__description {
    @apply text-white body-text-1;
  }

  :deep(&__button) {
    @apply button-shortcut;
  }
}
</style>