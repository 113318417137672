import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import screenSize from "@app/javascript/store/module/screenSize.js";

const store = new Vuex.Store({
  modules: {
    screenSize,
  },
});
export default store;
export const useStore = () => store;
