<template>
    <div class="ct-entry-display-case-studies">
        <div class="ct-entry-display-case-studies__header">
            <h2 class="ct-entry-display-case-studies__title" v-text="caseStudyTranslation.title" />
            <a target="_blank" :href="exploreAllIccasLink" class="ct-entry-display-case-studies__see-all"
                @mouseover="isHovering = true" @mouseleave="isHovering = false">
                <span class="ct-entry-display-case-studies__see-all-text" v-text="buttonsTranslation.see_all" />
                <IconArrow class="ct-entry-display-case-studies__arrow"
                    :class="{ 'button-arrow-right-animation': isHovering }" />
            </a>
        </div>
        <p class="ct-entry-display-case-studies__description" v-text="caseStudyTranslation.description" />

        <ol class="ct-entry-display-case-studies__icca-cards" v-if="numberOfCaseStudiesToShow.length > 0">
            <CardCaseStudy v-bind="{ caseStudy, translations }" :key="`${index}CardCaseStudy`"
                v-for="(caseStudy, index) in numberOfCaseStudiesToShow" />
        </ol>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import IconArrow from "@components/Icon/Arrow"
import CardCaseStudy from "@components/Card/CaseStudy"
import { useStore } from "@app/javascript/store/store";

const props = defineProps({
    translations: {
        type: Object,
        required: true
    },
    caseStudies: {
        type: Array,
        required: true
    },
    numberOfCaseStudiesByScreenSize: {
        type: Object,
        default: () => ({
            mobile: 1,
            tablet: 2,
            desktop: 3
        })
    },
    exploreAllIccasLink: {
        type: String,
        default: null
    }
})
const store = useStore()
const caseStudyTranslation = ref(props.translations.entry.display_case_studies)
const buttonsTranslation = ref(props.translations.buttons)
const screenIsMobile = computed(() => store.getters['screenSize/isMobile'])
const screenIsTablet = computed(() => store.getters['screenSize/isTablet'])

const numberOfCaseStudiesToShow = computed(() => {

    const numberCaseStrudies = props.caseStudies.length, numberOfCaseStudiesByScreenSize = props.numberOfCaseStudiesByScreenSize
    let numberOfCaseStudiesAllowed;
    if (!!screenIsMobile.value) {
        numberOfCaseStudiesAllowed = numberOfCaseStudiesByScreenSize['mobile']
    } else if (!!screenIsTablet.value) {
        numberOfCaseStudiesAllowed = numberOfCaseStudiesByScreenSize['tablet']
    } else {
        numberOfCaseStudiesAllowed = numberOfCaseStudiesByScreenSize['desktop']
    }
    // In case there are less case studies provided then the numberOfCaseStudiesAllowed by screen size
    if (numberOfCaseStudiesAllowed > numberCaseStrudies)
        numberOfCaseStudiesAllowed = numberCaseStrudies

    return props.caseStudies.filter((caseStudy, index) => { return index < numberOfCaseStudiesAllowed })
})
const isHovering = ref(false)
</script>

<style scoped lang="postcss">
.ct-entry-display-case-studies {
    @apply flex flex-col gap-4 md:gap-6;

    &__header {
        @apply flex items-center justify-between gap-y-6 flex-wrap w-full md:gap-y-0;
    }

    &__title {
        @apply subtitle-1 lg:title-1;
    }

    &__see-all {
        @apply flex items-center gap-1.5 md:gap-[0.5625rem];

        &-text {
            @apply text-theme-primary underlined-text md:underlined-text-2;
        }
    }

    &__arrow {
        @apply w-[0.55106rem] h-3 md:w-[0.82663rem] md:h-[1.125rem];
    }

    &__description {
        @apply body-text-2 lg:max-w-[53.5625rem];
    }

    &__icca-cards {
        @apply flex flex-col md:items-start md:flex-row md:gap-6;
    }
}
</style>