<template>
    <a :class="getCustomClass(classes, 'button-go-to-link__button')" @mouseover="isHovering = true"
        @mouseleave="isHovering = false" target="_blank" :href="link">
        <span v-text="text" :class="getCustomClass(classes, 'button-go-to-link__text')" />
        <IconArrow :class="arrowClass" />
    </a>
</template>

<script setup>
import { computed, ref } from 'vue'
import IconArrow from "@components/Icon/Arrow"
import getCustomClass from '@composition/getCustomClass';

const props = defineProps({
    link: {
        type: String,
        default: null
    },
    text: {
        type: String,
        default: null
    },
    classes: {
        type: Object,
        default: () => ({})
    }
})
const isHovering = ref(false)
const arrowClass = computed(() => {
    const classArrow = { 'button-arrow-right-animation': isHovering.value }
    if (props.classes['button-go-to-link__arrow'])
        classArrow[props.classes['button-go-to-link__arrow']] = true
    else
        classArrow['button-go-to-link__arrow'] = true
    return classArrow
})
</script>
<style scoped lang="postcss">
.button-go-to-link {
    &__button {
        @apply button-explore-summary;
    }

    &__text {
        @apply button-text;
    }

    &__arrow {
        @apply button-arrow;
    }
}
</style>
