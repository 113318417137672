<template>
    <div class="ct-map-geo-data-map" v-if="errorMessages.length === 0">
        <MapboxBanner v-if="!screenIsDesktop" v-bind="{
            translations,
            privacyPolicyLink,
            dataCollectionTermsLink
        }" />
        <MapboxMap class="ct-map-geo-data-map__container" :class="{ 'ct-map-geo-data-map__container--busy': busy }"
            v-bind="{ id: 'geoDataMap', configuration }" ref="mapboxMap" @load="onMapLoad">

            <MapboxSource v-bind="baseConfigurations.sources.polygons" :key="keys.sources.polygons">
                <MapboxLayer v-bind="baseConfigurations.layers.polygons" />
            </MapboxSource>
            <MapboxSource v-bind="baseConfigurations.sources.points" :key="keys.sources.points">
                <MapboxLayer v-bind="baseConfigurations.layers.points" />
            </MapboxSource>
            <MapboxPopup v-bind="{
                configuration: popupConfig,
                lngLat: popUpLngLat,
                title: iccaInfo.latin_name
            }" @close="closePopup">
                <MapboxIccaInfo v-bind="{ iccaInfo, translations, sitePath }" />
            </MapboxPopup>
            <MapboxBanner v-if="screenIsDesktop" v-bind="{
                translations,
                privacyPolicyLink,
                dataCollectionTermsLink
            }" />
        </MapboxMap>
        <MapboxDisclaimer v-bind="{ translations }" />
    </div>
    <BannerWarning v-else>
        <template #left>{{ errorMessagesTranslation.error_occurred }}</template>
        <template #right>
            <ol>
                <li :key="`errorMsg${index}`" v-for="(errorMessage, index) in errorMessages" v-text="errorMessage" />
            </ol>
        </template>
    </BannerWarning>
</template>

<script setup>
import { inject, ref, watch, computed } from 'vue'
import BannerWarning from "@components/Banner/Warning"
import MapboxMap from "@components/Mapbox/Map"
import MapboxSource from "@components/Mapbox/Source"
import MapboxLayer from "@components/Mapbox/Layer"
import MapboxPopup from "@components/Mapbox/Popup"
import MapboxBanner from "@components/Mapbox/Banner"
import MapboxIccaInfo from "@components/Mapbox/IccaInfo"
import MapboxDisclaimer from "@components/Mapbox/Disclaimer"
import { setCanvasStyle as mapboxSetCanvasStyle } from '@composition/setCanvasStyle'
import { useStore } from "@app/javascript/store/store";
import {
    POLYGON_COLOUR,
    ELEMENT_SCREENSHOT_MAPBOX_COMPATIBLE_CONFIGURATION,
    MAX_ZOOM,
    MIN_ZOOM,
    LAYER_ID_POINTS,
    CIRCLE_OPACITY,
    LAYER_ID_POLYGONS,
    MAP_ZOOM_DEFAULT,
    POLYGON_TOLERANCE,
    SOURCE_ID_POINTS,
    SOURCE_ID_POLYGONS,
    POLYGON_OPACITY,
    POLYGON_OUTLINE_COLOR,
    POINT_CIRCLE_COLOUR,
    POINT_CIRCLE_RADIUS,
    POINT_STROKE_WIDTH
} from '@root/constants'

const props = defineProps({
    geoJson: {
        type: Object,
        default: null
    },
    sitePath: {
        type: String,
        required: true,
    },
    dataCollectionTermsLink: {
        type: String,
        required: true
    },
    privacyPolicyLink: {
        type: String,
        required: true
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})
const $env = inject('$env')
const store = useStore()
const screenIsDesktop = computed(() => store.getters['screenSize/isDesktop'])
const geoDataMapTranslation = ref(props.translations.entry.geo_data_map)
const errorMessagesTranslation = props.translations.error_messages
const map = ref(null);
const busy = ref(false)
const errorMessages = ref([])
const popupConfig = ref({
    anchor: 'top'
})
const popUpLngLat = ref(null)
const iccaInfo = ref({})
const configuration = ref({
    accessToken: $env.mapbox.accessToken,
    style: $env.mapbox.mapStyle,
    zoom: MAP_ZOOM_DEFAULT,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    scrollZoom: !!store.getters['screenSize/isDesktop'],
    ...ELEMENT_SCREENSHOT_MAPBOX_COMPATIBLE_CONFIGURATION
})
const keys = ref({
    sources: {
        points: SOURCE_ID_POINTS,
        polygons: SOURCE_ID_POLYGONS
    }
})
const baseConfigurations = ref({
    sources: {
        points: {
            id: SOURCE_ID_POINTS,
            source: {
                type: 'geojson',
                data: props.geoJson.data.points,
            }
        },
        polygons: {
            id: SOURCE_ID_POLYGONS,
            source: {
                type: 'geojson',
                data: props.geoJson.data.polygons,
                tolerance: POLYGON_TOLERANCE
            }
        }
    },
    layers: {
        polygons: {
            id: LAYER_ID_POLYGONS,
            layer: {
                type: 'fill',
                source: SOURCE_ID_POLYGONS,
                paint: {
                    'fill-color': POLYGON_COLOUR,
                    'fill-opacity': POLYGON_OPACITY,
                    'fill-outline-color': POLYGON_OUTLINE_COLOR,
                }
            },
            order: 0,
            up: ({ map }) => {
                map.on('mouseenter', LAYER_ID_POLYGONS, onMouseenter)
                map.on('mouseleave', LAYER_ID_POLYGONS, onMouseleave)

                map.on(
                    'click',
                    LAYER_ID_POLYGONS,
                    onMapClick
                )
            },
            down: ({ map }) => {
                map.off('mouseenter', LAYER_ID_POLYGONS, onMouseenter)
                map.off('mouseleave', LAYER_ID_POLYGONS, onMouseleave)
                map.off(
                    'click',
                    LAYER_ID_POLYGONS,
                    onMapClick
                )
            }
        },
        points: {
            id: LAYER_ID_POINTS,
            layer: {
                type: 'circle',
                source: SOURCE_ID_POINTS,
                layout: {
                },
                paint: {
                    'circle-color': POINT_CIRCLE_COLOUR,
                    'circle-stroke-color': POINT_CIRCLE_COLOUR,
                    'circle-radius': POINT_CIRCLE_RADIUS,
                    'circle-stroke-width': POINT_STROKE_WIDTH,
                    'circle-opacity': CIRCLE_OPACITY
                }
            },
            order: 0,
            up: ({ map }) => {
                map.on('mouseenter', LAYER_ID_POINTS, onMouseenter)
                map.on('mouseleave', LAYER_ID_POINTS, onMouseleave)
                map.on('click', LAYER_ID_POINTS, onMapClick)
            },
            down: ({ map }) => {
                map.off('mouseenter', LAYER_ID_POINTS, onMouseenter)
                map.off('mouseleave', LAYER_ID_POINTS, onMouseleave)
                map.off('click', LAYER_ID_POINTS, onMapClick)
            }
        },
    }
})
function setBusy(_busy) {
    busy.value = _busy === true
}
function onMapLoad(e) {
    map.value = e.map
    map.value.setZoom(MAP_ZOOM_DEFAULT)
}
function onMouseenter(_e) {
    mapboxSetCanvasStyle(map.value, { cursor: 'pointer' })
}
function onMouseleave(_e) {
    mapboxSetCanvasStyle(map.value, { cursor: '' })
}
function closePopup() {
    popUpLngLat.value = null
    iccaInfo.value = {}
}
function openPopUp(lngLat) {
    popUpLngLat.value = lngLat
}
function onMapClick(e) {
    const features = e.features
    const { lng, lat } = e.lngLat
    if (features.length === 1) {
        const { properties, geometry } = features[0]
        const { type } = geometry
        iccaInfo.value = properties
        openPopUp(type === "Point" ? geometry.coordinates : [lng, lat])
    }
}
// Initilisation to check any errors for geo json before applying to the map
let { errors, status_code, data: geoJsonData } = props.geoJson
if (status_code !== 200) {
    errorMessages.value.push(...geoDataMapTranslation.value.default_error_messages)
    if (errors.length > 0) {
        errorMessages.value.push(...errors)
    }
} else {
    const { points, polygons } = geoJsonData
    if (!points || !polygons) {
        errorMessages.value.push(...geoDataMapTranslation.value.default_error_messages)
        errorMessages.value.push(geoDataMapTranslation.value.server_not_returning_correct_data)
    }
}

// Disable scrollZoom when screen has been reduced to tablet or mobile size
watch(() => store.getters['screenSize/isDesktop'], (screenIsDesktop) => {
    if (map.value) {
        if (screenIsDesktop) {
            map.value.scrollZoom.enable()
        } else {
            map.value.scrollZoom.disable()
        }
    }
})

console.log("points", props.geoJson?.data?.points);
console.log('polygons', props.geoJson?.data?.polygons);
</script>
<style lang="postcss" scoped>
.ct-map-geo-data-map {
    @apply flex flex-col mb-2;

    &__container {
        @apply relative border-2 border-theme-primary h-[45rem] lg:h-[51.875rem];
    }
}
</style>
