<template>
    <ol class="ct-entry-data-summary-charts">
        <CardDataSummaryChart :translations="translations" v-bind="chartComponentProps(chartInfomation, index)"
            :key="`CardDataSummaryChart${index}`" v-for="(chartInfomation, index) in chartsInformation" />
    </ol>
</template>

<script setup>
import CardDataSummaryChart from '@components/Card/DataSummary/Chart'

const props = defineProps({
    isBarChart: {
        type: Boolean,
        default: false
    },
    chartsInformation: {
        type: Array,
        default: () => []
    },
    translations: {
        type: Object,
        default: () => ({})
    },
    showTopXInLegends: {
        type: Number,
        default: 5
    }
})

function chartComponentProps({ chart_key, data }, index) {
    const translations = props.translations.entry.data_summary.charts[chart_key]
    return {
        isBarChart: props.isBarChart,
        title: translations.title,
        description: translations.description,
        rawChartInformation: data,
        showTopXInLegends: props.showTopXInLegends,
        currentChartIndex: index
    }
}
</script>
<style lang="postcss" scoped>
.ct-entry-data-summary-charts {
    @apply grid grid-cols-1 content-start gap-4 md:gap-6 lg:grid-cols-2;

}
</style>
