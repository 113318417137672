import { breakpoints } from "@root/tailwind/tailwind-breakpoints.config.js";

export default {
  namespaced: true,

  state: {
    windowWidth: window.innerWidth,
  },
  getters: {
    isMobile: (state) => {
      return state.windowWidth < breakpoints.md;
    },
    isTablet: (state) => {
      return (
        state.windowWidth >= breakpoints.md &&
        state.windowWidth < breakpoints.lg
      );
    },
    isDesktop: (state) => {
      return state.windowWidth >= breakpoints.lg;
    },
    windowWidth: (state) => {
      return state.windowWidth;
    },
  },
  actions: {
    updateWindowWidth({ commit }) {
      commit("setWindowWidth", window.innerWidth);
    },
  },
  mutations: {
    setWindowWidth(state, width) {
      state.windowWidth = width;
    },
  },
};
