import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=19c05763&scoped=true"
import script from "./Popup.vue?vue&type=script&setup=true&lang=js"
export * from "./Popup.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Popup.vue?vue&type=style&index=0&id=19c05763&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c05763",
  null
  
)

export default component.exports