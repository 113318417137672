<template>
  <div class="ct-mapbox-map">
    <div ref="mapRef" :id="id" class="ct-mapbox-map__container" />

    <div v-if="loaded" class="ct-mapbox-map__slot">
      <slot />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, provide } from 'vue'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  props: {
    id: {
      type: String,
      requried: true
    },
    configuration: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const map = ref()
    const loaded = ref(false)
    provide('getMapObject', map)

    onMounted(() => {
      map.value = new mapboxgl.Map({
        container: props.id,
        ...props.configuration
      })
      map.value.once('load', () => {
        emit('load', { map })
        map.value.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right')
        loaded.value = true
      })
    })
    return {
      map,
      loaded
    }
  },
  destroyed() {
    this.map.remove()
  }
}
</script>

<style lang="postcss" scoped>
.ct-mapbox-map {
  @apply relative h-full w-full;

  &__container {
    @apply absolute top-0 bottom-0 w-full h-full;
  }

  &__slot {
    .mapboxgl-ctrl-bottom-right {
      @apply inline;
    }
  }
}

:deep(.mapboxgl-ctrl-attrib-inner) {
  @apply hidden;
}

:deep(.mapboxgl-ctrl) {
  button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    @apply text-theme-primary;

    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M13.3854 0.557885L10.8399 0.532848L10.7398 10.7147L0.557954 10.6145L0.532917 13.16L10.7148 13.2602L10.6146 23.442L13.1601 23.467L13.2602 13.2852L23.4421 13.3853L23.4671 10.8399L13.2853 10.7397L13.3854 0.557885Z' fill='currentColor' /%3E%3C/svg%3E");
  }

  button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    @apply text-theme-primary;

    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 4' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M17.804.76H.196C.088.76 0 .879 0 1.023v1.956c0 .144.088.261.196.261h17.608c.108 0 .196-.117.196-.26V1.021c0-.144-.088-.261-.196-.261Z' fill='currentColor' /%3E%3C/svg%3E");
  }
}

:deep(.mapboxgl-ctrl-group) {
  @apply bg-white border-theme-primary border-2 border-solid text-theme-primary;

  &:not(:empty) {
    @apply shadow-none rounded-none;
  }

  button {
    @apply border-theme-primary border w-8 h-8 p-2;

    &:first-child {
      @apply rounded-none;
    }
  }
}
</style>
