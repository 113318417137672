module.exports = {
  primary: "#712213",
  "primary-darker": "#5F1F10",
  warning: "#FFF0F5",
  banner: "#E7F2E4",
  grey: {
    light: "#DFE2E0",
    medium: "#666666",
  },
  highlights: {
    default: "#712213",
    set: {
      green: "#098649",
      orange: "#DC5426",
      purple: "#5E3AB6",
      blue: "#305395",
    },
  },
  // the following is mainly used for the @root/constants.js and app/javascript/components/Card/DataSummary/Chart.vue
  charts: {
    background: "#F2F2F2",
    default: "#712213",
    sets: {
      purple: {
        700: "#2A1161",
        600: "#5E3AB6",
        500: "#8B6FD6",
        400: "#AFA1D2",
        300: "#C8C2E1",
      },
      orange: {
        700: "#AE1609",
        600: "#DC5426",
        500: "#E5772E",
        400: "#E89937",
        300: "#EDC28C",
      },
      green: {
        700: "#075732",
        600: "#098649",
        500: "#5BBA6F",
        400: "#8CCF9A",
        300: "#B7E1C0",
      },
      blue: {
        700: "#1D2F60",
        600: "#305395",
        500: "#5A7BB6",
        400: "#8AA4CE",
        300: "#BDCBE2",
      },
    },
  },
  "case-study": {
    "no-image": "#E7F2E4",
  },
  dropdown: {
    hover: "#5F1F10",
  },
};
