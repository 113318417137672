var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"ct-card-shortcut-link",style:(_setup.backgroundImageStyle)},[_c('div',{staticClass:"ct-card-shortcut-link__container"},[_c('div',{staticClass:"ct-card-shortcut-link__container--tooltip"},[_c(_setup.TooltipCitation,_vm._b({staticClass:"carousel__tooltip"},'TooltipCitation',{
        title: _vm.imageCitationTranslation.citation,
        description: _vm.shortcutLink.citation,
        isLast: _vm.isLast
      },false))],1),_vm._v(" "),_c('div',{staticClass:"ct-card-shortcut-link__container--content"},[_c('img',{staticClass:"ct-card-shortcut-link__icon",attrs:{"alt":`${_vm.shortcutLink.title}-svg`,"src":_vm.shortcutLink.icon_link}}),_vm._v(" "),_c('span',{staticClass:"ct-card-shortcut-link__title",domProps:{"textContent":_vm._s(_vm.shortcutLink.title)}}),_vm._v(" "),_c('p',{staticClass:"ct-card-shortcut-link__description",domProps:{"textContent":_vm._s(_vm.shortcutLink.description)}}),_vm._v(" "),_c(_setup.ButtonGoToLink,_vm._b({},'ButtonGoToLink',{
        link: _vm.shortcutLink.hyperlink,
        text: _vm.shortcutLink.button,
        classes: { 'button-go-to-link__button': 'ct-card-shortcut-link__button' }
      },false))],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }