var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.errorMessages.length === 0)?_c('div',{staticClass:"ct-map-geo-data-map"},[(!_setup.screenIsDesktop)?_c(_setup.MapboxBanner,_vm._b({},'MapboxBanner',{
        translations: _vm.translations,
        privacyPolicyLink: _vm.privacyPolicyLink,
        dataCollectionTermsLink: _vm.dataCollectionTermsLink
    },false)):_vm._e(),_vm._v(" "),_c(_setup.MapboxMap,_vm._b({ref:"mapboxMap",staticClass:"ct-map-geo-data-map__container",class:{ 'ct-map-geo-data-map__container--busy': _setup.busy },on:{"load":_setup.onMapLoad}},'MapboxMap',{ id: 'geoDataMap', configuration: _setup.configuration },false),[_c(_setup.MapboxSource,_vm._b({key:_setup.keys.sources.polygons},'MapboxSource',_setup.baseConfigurations.sources.polygons,false),[_c(_setup.MapboxLayer,_vm._b({},'MapboxLayer',_setup.baseConfigurations.layers.polygons,false))],1),_vm._v(" "),_c(_setup.MapboxSource,_vm._b({key:_setup.keys.sources.points},'MapboxSource',_setup.baseConfigurations.sources.points,false),[_c(_setup.MapboxLayer,_vm._b({},'MapboxLayer',_setup.baseConfigurations.layers.points,false))],1),_vm._v(" "),_c(_setup.MapboxPopup,_vm._b({on:{"close":_setup.closePopup}},'MapboxPopup',{
            configuration: _setup.popupConfig,
            lngLat: _setup.popUpLngLat,
            title: _setup.iccaInfo.latin_name
        },false),[_c(_setup.MapboxIccaInfo,_vm._b({},'MapboxIccaInfo',{ iccaInfo: _setup.iccaInfo, translations: _vm.translations, sitePath: _vm.sitePath },false))],1),_vm._v(" "),(_setup.screenIsDesktop)?_c(_setup.MapboxBanner,_vm._b({},'MapboxBanner',{
            translations: _vm.translations,
            privacyPolicyLink: _vm.privacyPolicyLink,
            dataCollectionTermsLink: _vm.dataCollectionTermsLink
        },false)):_vm._e()],1),_vm._v(" "),_c(_setup.MapboxDisclaimer,_vm._b({},'MapboxDisclaimer',{ translations: _vm.translations },false))],1):_c(_setup.BannerWarning,{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v(_vm._s(_setup.errorMessagesTranslation.error_occurred))]},proxy:true},{key:"right",fn:function(){return [_c('ol',_vm._l((_setup.errorMessages),function(errorMessage,index){return _c('li',{key:`errorMsg${index}`,domProps:{"textContent":_vm._s(errorMessage)}})}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }