<template>
    <div class="ct-legend-data-summary">
        <span class="ct-legend-data-summary__title" v-text="title" />
        <ol class="ct-legend-data-summary__legends">
            <li class="ct-legend-data-summary__legend" :key="`ct-legend-data-summary__legend${index}`"
                v-for="(legend, index) in legends">
                <div class="ct-legend-data-summary__circle" :style="{ backgroundColor: legend.colour }" />
                <span class="ct-legend-data-summary__description">
                    <span v-text="legend.key" />
                    <span v-text="'-'" />
                    <span v-text="legend.numberOfTimesTheNameAppear" class="ct-legend-data-summary__description--bold" />
                </span>
            </li>
        </ol>
    </div>
</template>

<script setup>
import { replaceTranslationArguments } from '@composition/replaceTranslationArguments'

const props = defineProps({
    legends: {
        type: Array,
        default: () => []
    },
    translations: {
        type: Object,
        default: () => ({})
    }
})

const title = replaceTranslationArguments(props.translations.legend.data_summary, { number: props.legends.length })
</script>

<style lang="postcss" scoped>
.ct-legend-data-summary {
    @apply flex flex-col gap-1.5 md:gap-3;

    &__title {
        @apply small-text-1;
    }

    &__legends {
        @apply flex flex-col gap-1.5 md:gap-3;
    }

    &__legend {
        @apply flex items-center gap-3;
    }

    &__circle {
        @apply w-3 h-3 align-middle rounded-full shrink-0;
    }

    &__description {
        @apply data-summary-legend-text;

        &--bold {
            @apply data-summary-legend-text--bold;
        }
    }
}
</style>