<template>
    <div class="ct-modal-geo-download">
        <button @click="toggleModal" class="ct-modal-geo-download__open-modal--button">
            <IconDownload class="ct-modal-geo-download__open-modal--icon" />
            <span class="ct-modal-geo-download__open-modal--text" v-text="buttonTranslation.download_the_data" />
        </button>
        <div class="ct-modal-geo-download__container">
            <div v-show="showModal" ref="tooltipModal" role="tooltip" class="ct-modal-geo-download__modal">
                <div class="ct-modal-geo-download__modal--content">
                    <div class="ct-modal-geo-download__modal--top">
                        <span class="ct-modal-geo-download__modal--title" v-text="buttonTranslation.download_the_data" />
                        <button @click="toggleModal">
                            <IconPlus class="ct-modal-geo-download__close-button--icon" />
                        </button>
                    </div>
                    <hr>
                    <form class="ct-modal-geo-download__download-form" @submit.prevent="downloadFile">
                        <div class="ct-modal-geo-download__download-form--element">
                            <div class="ct-modal-geo-download__download-form--left-element">
                                <label for="email">
                                    <span class="ct-modal-geo-download__required" v-text="'*'" />
                                    <span class="ct-modal-geo-download__download-form--label"
                                        v-text="geoDownloadTranslation.email" />
                                </label>
                            </div>
                            <div class="ct-modal-geo-download__download-form--right-element">
                                <input v-model.trim="email" class="ct-modal-geo-download__download-form--email" name="email"
                                    type="email" required>
                            </div>
                        </div>
                        <div class="ct-modal-geo-download__download-form--element">
                            <div class="ct-modal-geo-download__download-form--left-element">
                                <label for="file_type">
                                    <span class="ct-modal-geo-download__required" v-text="'*'" />
                                    <span class="ct-modal-geo-download__download-form--label"
                                        v-text="geoDownloadTranslation.file_type" />
                                </label>
                            </div>
                            <div class="ct-modal-geo-download__download-form--right-element">
                                <select v-model="fileType" name="file_type"
                                    class="ct-modal-geo-download__download-form--file-type" required>
                                    <option v-for="(option, index) in downloadOptions" :value="option.type"
                                        v-text="option.name" :key="`option.name${index}`" />
                                </select>
                            </div>
                        </div>
                        <div class="ct-modal-geo-download__download-form--element">
                            <label class="ct-modal-geo-download__download-form--terms-conditions">
                                <input class="ct-modal-geo-download__download-form--checkbox" v-model="dataCollectionAgreed"
                                    type="checkbox" name="dataCollectionAgreed" required>
                                <span>
                                    <span class="ct-modal-geo-download__required" v-text="'*'" />
                                    <span v-html="privicyLinkText" />
                                </span>
                            </label>
                        </div>
                        <div class="ct-modal-geo-download__download-form--element">
                            <label class="ct-modal-geo-download__download-form--terms-conditions">
                                <input class="ct-modal-geo-download__download-form--checkbox"
                                    v-model="termsConditionsAgreed" type="checkbox" name="termsConditionsAgreed" required>
                                <span>
                                    <span class="ct-modal-geo-download__required" v-text="'*'" />
                                    <span v-html="dataCollectionText" />
                                </span>
                            </label>
                        </div>
                        <BannerWarning v-if="errorMessages.length > 0">
                            <template #right>
                                <ol>
                                    <li :key="`errorMsg${index}`" v-for="(errorMessage, index) in errorMessages"
                                        v-text="errorMessage" />
                                </ol>
                            </template>
                        </BannerWarning>
                        <button :disabled="fieldsThatAreNotFilled.length > 0"
                            class="ct-modal-geo-download__download-button--button">
                            <IconDownload class="ct-modal-geo-download__download-button--icon" />
                            <span class="ct-modal-geo-download__download-button--text"
                                v-text="buttonTranslation.download" />
                        </button>
                    </form>

                </div>
            </div>
        </div>
        <Loader v-if="showLoader" />
    </div>
</template>

<script setup>
import { ref, nextTick, computed, watch, onUnmounted } from 'vue'
import IconPlus from "@components/Icon/Plus"
import IconDownload from "@components/Icon/Download"
import Loader from '@components/Loader.vue'
import { apiPaths, postHeaders } from '@composition/apiRelatedFunctions'
import axios from 'axios'
import BannerWarning from "@components/Banner/Warning"
import { COOKIENAMES } from '@root/constants'
import { userConsentedForOptionalCookies, setCookie, getCookie, removeCookie } from '@composition/cookie'
import { replaceTranslationArguments, buildLinkElement } from '@composition/replaceTranslationArguments'
import { disableBackgroundScrolling, enableBackgroundScrolling, scrollingIsDisabled } from '@composition/backgroundScrollingControl'

const props = defineProps({
    translations: {
        type: Object,
        required: true
    },
    dataCollectionTermsLink: {
        type: String,
        required: true
    },
    privacyPolicyLink: {
        type: String,
        required: true
    },
})
const tooltipModal = ref()
const showModal = ref(false)
const showLoader = ref(false)
const buttonTranslation = props.translations.buttons
const geoDownloadTranslation = props.translations.modal.geo_download
const errorMessagesTranslation = props.translations.error_messages
const downloadOptions = [
    {
        name: geoDownloadTranslation.file_types.shp,
        type: 'shp'
    },
    {
        name: geoDownloadTranslation.file_types.gdb,
        type: 'gdb'
    },
    {
        name: geoDownloadTranslation.file_types.csv,
        type: "csv"
    }
]
const errorMessages = ref([])
const userOptedInOptionalCookies = userConsentedForOptionalCookies()
// Only return emails if the user opt in from cookie banner
const existingEmail = userOptedInOptionalCookies ? getCookie(COOKIENAMES.emailDownloadGeoFile) : ''
const email = ref(existingEmail || '')
const fileType = ref(downloadOptions[0].type)
const termsConditionsAgreed = ref(false)
const dataCollectionAgreed = ref(false)
const fieldsThatAreNotFilled = computed(() => {
    const messages = []
    if (!email.value) {
        messages.push(`${geoDownloadTranslation.email} ${geoDownloadTranslation.is_empty}`)
    }
    if (!fileType.value) {
        messages.push(`${geoDownloadTranslation.file_type} ${geoDownloadTranslation.is_empty}`)
    }
    if (!dataCollectionAgreed.value) {
        messages.push(geoDownloadTranslation.data_collection_empty)
    }
    if (!termsConditionsAgreed.value) {
        messages.push(geoDownloadTranslation.terms_conditions_empty)
    }
    return messages
})
const dataCollectionText = replaceTranslationArguments(geoDownloadTranslation.data_collection_terms.text, { link: buildLinkElement(props.dataCollectionTermsLink, geoDownloadTranslation.data_collection_terms.link) })
const privicyLinkText = replaceTranslationArguments(geoDownloadTranslation.privacy_policy_terms.text, { link: buildLinkElement(props.privacyPolicyLink, geoDownloadTranslation.privacy_policy_terms.link) })
function toggleModal() {
    showModal.value = !showModal.value
    if (showModal.value) {
        nextTick(() => {
            tooltipModal.value.scrollIntoView({ behavior: 'smooth' })
        })
    }
}
function downloadFile() {
    errorMessages.value = []
    showLoader.value = true
    if (fieldsThatAreNotFilled.value.length === 0) {
        const downloadLink = apiPaths.geoFile
        try {
            axios.post(downloadLink, {
                email: email.value,
                file_type: fileType.value,
                terms_conditions_agreed: termsConditionsAgreed.value,
                user_data_collection_agreed: dataCollectionAgreed.value,
                method: "WEBSITE",
            }, { headers: postHeaders }).then((response) => {
                const { status, file_url } = response.data.data
                showLoader.value = false
                if (status === "completed") {
                    window.open(file_url, '_blank');
                    showModal.value = false
                } else if (status === "processing") {
                    errorMessages.value.push(geoDownloadTranslation.generating)
                } else {
                    errorMessages.value.push(geoDownloadTranslation.error_msg)
                }
            }).catch(({ response }) => {
                showLoader.value = false
                const { errors, status_code } = response.data
                console.log(status_code, errors);
                errorMessages.value.push(geoDownloadTranslation.error_msg)
            })
        } catch (error) {
            errorMessages.value.push(errorMessagesTranslation.error_occurred)
            showLoader.value = false
        }
    }
    else {
        errorMessages.value = fieldsThatAreNotFilled.value
    }
}
// Store the email to cookie for next time usage if an user opts in for optional cookies
if (userOptedInOptionalCookies === true) {
    watch(() => email.value, (newValue) => {
        if (userOptedInOptionalCookies === true) {
            setCookie(COOKIENAMES.emailDownloadGeoFile, email.value);
        }
    })
}
// Remove cookies if an user previously has the cookie stored and is now opting out
if (userOptedInOptionalCookies === false && !!getCookie(COOKIENAMES.emailDownloadGeoFile)) { removeCookie(COOKIENAMES.emailDownloadGeoFile) }

watch(() => showModal.value, (newValue) => {
    newValue ? disableBackgroundScrolling() : enableBackgroundScrolling()
})
onUnmounted(() => {
    if (scrollingIsDisabled())
        enableBackgroundScrolling()
})
</script>

<style lang="postcss" scoped>
.ct-modal-geo-download {

    &__container {
        @apply relative;
    }

    &__open-modal {
        &--button {
            @apply button-download;
        }

        &--icon {
            @apply button-download-icon;
        }

        &--text {
            @apply button-text;
        }
    }

    &__close-button {
        &--icon {
            @apply text-black w-4 rotate-45 cursor-pointer;
        }
    }

    &__download-button {
        &--button {
            @apply button-download bg-theme-primary disabled:bg-theme-primary/60 disabled:cursor-not-allowed;
        }

        &--icon {
            @apply button-download-icon;
        }

        &--text {
            @apply button-text;
        }
    }

    &__modal {
        @apply overflow-visible left-0 top-0 bottom-0 right-0 h-full w-full fixed bg-gray-200/70 z-50 flex justify-center items-center;

        &--content {
            @apply flex flex-col justify-center bg-white max-w-[25rem] md:max-w-[45rem];
        }

        &--top {
            @apply flex justify-between items-center p-4;
        }

        &--title {
            @apply subtitle-2;
        }

    }

    &__download-form {
        @apply flex flex-col gap-3 p-4;

        &--element {
            @apply md:flex md:items-center mb-6;
        }

        &--label {
            @apply subtitle-3;
        }

        &--left-element {
            @apply md:w-1/5;
        }

        &--right-element {
            @apply md:w-4/5;
        }

        &--email {
            @apply w-full p-[0.625rem];
        }

        &--file-type {
            @apply w-full;
        }

        &--terms-conditions {
            @apply flex gap-3 small-text-1 items-start;
        }

        &--checkbox {
            @apply shrink-0 w-4 h-4 accent-theme-primary mt-[0.15rem];
        }
    }

    &__required {
        @apply text-red-800 text-base;
    }

}
</style>